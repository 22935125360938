<template>
  <div class="item-add">
    <div class="icon" v-if="icon">
      <svg
        v-if="icon=='date'"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
      >
        <path
          fill="#917c3e"
          fill-rule="nonzero"
          d="M14 4.5a9.5 9.5 0 1 1 0 19 9.5 9.5 0 0 1 0-19zm0 1.571a7.929 7.929 0 1 0 0 15.858A7.929 7.929 0 0 0 14 6.07zm.786 1.571l.072.073-.021 5.904 3.7 3.7v.103l-1.115 1.115h-.103l-4.177-4.215V7.715l.072-.073h1.572z"
        />
      </svg>

      <svg
        v-if="icon=='place'"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
      >
        <path
          fill="#917c3e"
          fill-rule="nonzero"
          d="M14 3c4.392 0 8 3.482 8 7.8 0 1.72-.996 3.984-2.849 6.968-.28.453-.58.92-.905 1.413l-.824 1.228c-.728 1.067-2.05 2.978-2.29 3.326-.602.732-1.704.762-2.237.045l-2.237-3.254a70.853 70.853 0 0 1-1.809-2.758C6.996 14.784 6 12.52 6 10.801 6 6.456 9.553 3 14 3zm0 1.43c-3.666 0-6.57 2.825-6.57 6.37 0 1.364.92 3.452 2.634 6.214.343.552.715 1.128 1.13 1.75l.568.843 2.237 3.254 2.554-3.717.497-.748c.319-.484.612-.94.886-1.382l.355-.582c1.486-2.475 2.279-4.366 2.279-5.631 0-3.517-2.957-6.37-6.57-6.37zm.037 3.537c1.737 0 3.106 1.461 3.106 3.237 0 1.767-1.362 3.16-3.106 3.16a3.165 3.165 0 0 1-3.18-3.16c0-1.767 1.437-3.237 3.18-3.237zm0 1.43c-.946 0-1.75.823-1.75 1.807 0 .963.783 1.73 1.75 1.73.947 0 1.676-.746 1.676-1.73 0-1.006-.75-1.807-1.676-1.807z"
        />
      </svg>
    </div>
    <div class="text">
      <span v-if="title">
        {{title.charAt(0).toUpperCase()+title.slice(1)}}:
        <br />
      </span>
      <span v-if="value" :class="{'regular':title}">{{value}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "value"]
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.item-add {
  display: flex;
  margin-bottom: 16px;

  .icon {
    margin-top: -3px;
    margin-right: 8px;
  }

  .text {
    font-family: $ObjectSans;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: rgba(#fff, 0.97);
    .regular {
      font-weight: normal;
    }
  }
}
</style>